import React, { useState, useEffect } from 'react';

const CookieConsentBanner = () => {
    const [consent, setConsent] = useState({
        necessary: true,
        analytics: false,
        preferences: false,
        marketing: false
    });

    // Hide or show the banner based on consent being stored in localStorage
    useEffect(() => {
        const savedConsent = localStorage.getItem('consentMode');
        if (savedConsent) {
            document.getElementById('cookie-consent-banner').style.display = 'none';
        } else {
            document.getElementById('cookie-consent-banner').style.display = 'block';
        }
    }, []);

    // Function to handle consent changes
    const handleConsent = (newConsent) => {
        setConsent(newConsent);
        updateConsentInLocalStorage(newConsent);
        document.getElementById('cookie-consent-banner').style.display = 'none';
    };

    // Store consent in localStorage and handle external consent management, e.g., Google Tag Manager
    const updateConsentInLocalStorage = (consent) => {
        const consentMode = {
            'ad_storage': consent.marketing ? 'granted' : 'denied',
            'analytics_storage': consent.analytics ? 'granted' : 'denied',
            'ad_user_data'	:  consent.analytics ? 'granted' : 'denied',
            'ad_personalization': consent.analytics ? 'granted' : 'denied',
        };
        // Assuming gtag is globally available
        window.gtag && window.gtag('consent', 'update', consentMode);
        localStorage.setItem('consentMode', JSON.stringify(consentMode));
    };

    return (
        <div id="cookie-consent-banner" className="cookie-consent-banner">
            <h3>Подешавања колачића</h3>
            <p> Користимо колачиће да вам пружимо најбоље могуће искуство. Они нам такође омогућавају да анализирамо понашање корисника како бисмо стално унапређивали веб сајт за вас.</p>
            <button className="cookie-consent-button btn-success" onClick={() => handleConsent({ necessary: true, analytics: true, preferences: true, marketing: true })}>Прихвати све</button>
            <button className="cookie-consent-button btn-outline" onClick={() => handleConsent({ ...consent, necessary: true })}> Прихвати изабрано</button>
            <button className="cookie-consent-button btn-grayscale" onClick={() => handleConsent({ necessary: true, analytics: false, preferences: false, marketing: false })}>Одбаци све</button>
            <div className="cookie-consent-options">
                <label className="switch">
                <input type="checkbox" value="Analytics" checked={consent.analytics} onChange={() => setConsent({ ...consent, analytics: !consent.analytics })} />
                <span className="slider round"></span>
                <span className="text">Аналитика</span> 
                </label>
                <label className="switch">
                    <input type="checkbox" value="Marketing" checked={consent.marketing} onChange={() => setConsent({ ...consent, marketing: !consent.marketing })} />
                <span className="slider round"></span>
                <span className="text">Маркетинг</span>
                </label>
            </div>
            
            
          
        </div>
    );
};

export default CookieConsentBanner;








/* HEADER
<meta name="google-site-verification" content="QOxHE65dI2eHWQZ1HHGVB0tIob_bPnemyMGsT-idZLI" />
<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=AW-11363651361"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'AW-11363651361');
</script>

<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NHCWPWRM');</script>
<!-- End Google Tag Manager -->
*/


/*footer

<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NHCWPWRM"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
*/