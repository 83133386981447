import React from 'react';
import { Navigate } from 'react-router-dom';
import {AUTH_TOKEN} from '../enums'



const ProtectedRoute = ({ element: Component }) => {
  const isAuthenticated = !!localStorage.getItem(AUTH_TOKEN);

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;