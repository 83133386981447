import {  action, makeAutoObservable , observable} from 'mobx';

class CoursesStore {
    store;
    
  courses = observable([{'br':0.05277}, {'ru':0.8008}, {'in':0.7831},{'ch':0.06697},{'sa':0.1714}]);
  constructor(store) {

    this.store = store;
    makeAutoObservable(this);
  }
    setCourses = action(data => {
      this.courses = data
    })
  
    getAllCourses(page=1, size=50) {
      return this.store.apiStore.get({
        url: `/entry/courses`, 
        headers:{'accept': 'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
            
          this.setCourses(data)
    
          //this.setWebTests(data['items'])
          //this.setPage(data['page'])
          //this.setTotal(data['total'])
          //this.setSize(data['size'])
          //this.setPagination(true)
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)))
      .catch(err => this.setViewErrorMessage(err))
    }

}
export default CoursesStore