import { observable, action } from 'mobx';

class PaginationStore {
    page = observable(0);
    size = observable(10);
    pagination = observable(true);
    total = observable(0);

    setSize = action(size => {
        this.size = size
    })
    
    setPage = action(page => {
        this.page = page
    })

    setPagination = action(page => {
        this.pagination = page
    })

    setTotal = action(tot => {
        this.total = tot
    })
}
export default PaginationStore