import React from 'react';
import HeaderCompoent from '../../components/Header/HeaderComponent';
import LeftSidebar from '../../components/Sidebar/LeftSidebar';
import RightSidebar from '../../components/Sidebar/RightSidebar';
import InputFormComponent from '../../components/Forms/InputFormComponent';
import { useParams } from 'react-router-dom';



const ArticlePage = () => {
  const { id } = useParams() ;


  return (
    <div>
       <HeaderCompoent />
       <div className="container">
      
      <LeftSidebar />
        <div className='main'><InputFormComponent id={id} /></div>
      <RightSidebar />
      </div>
    </div>
  );
};
export default ArticlePage ;