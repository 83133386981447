import { action } from 'mobx';
import env from '../env/environment'

class APIStore {
    store;

    constructor(store){
        this.store = store
    }

     static checkStatus = action(response => {
        if (response.status >= 200 && response.status < 300) {
            return response;
        }
        if (response.status === 401 ){
            window.location.href = '/login'
        }
        return Promise.reject(response)
    })

    appendParams(url, params){
        Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    };

    get = action(({url, params={}, headers={}, auth_headers={}, prefix_with_env=true} = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL}${url}` : `${url}`)
        headers = {...headers, ...auth_headers}
        this.appendParams(url, params)
        return fetch(url, {
            method: 'GET',
            headers: headers
        }).then(APIStore.checkStatus)
    });

    post = action(({url, params={}, body={}, headers={}, auth_headers={}, prefix_with_env=true} = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL}${url}` : `${url}`)
        headers = {...headers, ...auth_headers}
        this.appendParams(url, params)
        return fetch(url,{
            method: 'POST',
            body: body,
            headers: headers
        }).then(APIStore.checkStatus)
    });

    put = action(({url, params={}, body={}, headers={}, auth_headers={}, prefix_with_env=true} = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL}${url}` : `${url}`)
        headers = {...headers, ...auth_headers}
        this.appendParams(url, params)
        return fetch(url,{
            method: 'PUT',
            body: body,
            headers: headers
        }).then(APIStore.checkStatus)
    });

    delete = action(({url, params={}, body={}, headers={}, auth_headers={}, prefix_with_env=true} = {}) => {
        url = new URL(prefix_with_env ? `${env.API_URL}${url}` : `${url}`)
        headers = {...headers, ...auth_headers}
        this.appendParams(url, params)
        return fetch(url,{
            method: 'DELETE',
            body: body,
            headers: headers
        }).then(APIStore.checkStatus)
    });

    get_static_url = action(() => {
        return `${env.API_STATIC}/static`
    })

    saveFile = action(({url, headers={}, auth_headers={}, prefix_with_env=true}={}) => {
        headers = {...headers, ...auth_headers}
        return  this.get({
            url: url,
            headers: headers,
            prefix_with_env: prefix_with_env
        })
        .then(APIStore.checkStatus)
    })



}
export default APIStore;