const env_shared = {
    FRONTEND_VERSION: `1.0.0`
}

const dev_env = {
    // API_URL: process.env.REACT_APP_API_URL,
    // API_STATIC: process.env.API_STATIC ? process.env.API_STATIC : process.env.REACT_APP_API_URL.replace('/api_v1', ''),
    // REPORT_PORTAL_SUBPAGE: `http://localhost:3001`,
    API_URL: 'http://localhost',
    API_STATIC: 'http://localhost',
}

const prod_URL = 'https://uaok8sfzg5.execute-api.eu-north-1.amazonaws.com'
const prod_env = {
    API_URL: `${prod_URL}`,
    API_STATIC: `${prod_URL}`,
}

let env;
if (process.env.NODE_ENV === 'production'){
    env = Object.assign({}, prod_env, env_shared)
}
else {
    env = Object.assign({}, dev_env, env_shared)
}


export default env;