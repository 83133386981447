import {  action, extendObservable} from 'mobx';
import PaginationStore from './paginationStore';

class ArticleStore extends PaginationStore {
    store;

  constructor(store) {
    
    super()
    this.store = store;
    extendObservable(this, {
        articles: [],
        formData: this.getDefaultFormData(),
        response: {message:""},
        isPage: false,

      });
    }
    getDefaultFormData() {
      return {
        id: '',
        title: '',
        subtitle: '',
        img_url: '',
        hero_img_url: '',
        author: '',
        content: '',
        source_url: '',
        image_alt: ''
      };
    }
    resetFormData = action((data)=>{
      this.formData = data
    })


    setFormData = action(data => {
      const { name, value } = data


      this.formData[name] = value 

    })
  

    setFormDataFromSingleArticle = action(data => {
  
      this.formData = {
        title: data.title || '',
        subtitle: data.subtitle || '',
        img_url: data.img_url || '',
        hero_img_url: data.hero_img_url || '',
        author: data.author || '',
        content: data.content || '',
        source_url: data.source_url || '',
        image_alt: data.image_alt || ''
        };
    })

    setIsPage = action(data => {
      this.isPage = data
    })


    
    setArticles = (data => {
      if (this.isPage) {
        this.articles = data
      }else{

        this.articles = [...this.articles, ...data] 
      

      }
      
    })
    setResponse = action(data => {
      this.response = data
    })
    getSingleArticle(id) {

      return this.store.apiStore.get({
        url: `/article/articles/${id}`, 
        headers:{'accept': 'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
          this.setFormDataFromSingleArticle(data)
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)))
      .catch(err => this.setViewErrorMessage(err))
    }




    getAllArticles(page=0, size=10) {
      return this.store.apiStore.get({
        url: `/article/articles`, 
        params: { page, size }, // Add pagination parameters here
        headers:{'accept': 'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
          this.setArticles(data['data'])
        
          //this.setWebTests(data['items'])
          this.setPage(data['page'])
          //this.setTotal(data['total'])
          this.setSize(data['size'])
          //this.setPagination(true)
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)))
      .catch(err => this.setViewErrorMessage(err))
    }


    createNewArticle() {
      return this.store.apiStore.post({
        url: `/article/article`, 
        body: JSON.stringify(this.formData),
        headers:{'accept': 'application/json', 'Content-Type':'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
        this.setResponse(data)
        this.resetFormData(this.getDefaultFormData())
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)))
      .catch(err => this.setViewErrorMessage(err))
    }


    updateArticle(id) {
      return this.store.apiStore.put({
        url: `/article/article/${id}`, 
        body: JSON.stringify(this.formData),
        headers:{'accept': 'application/json', 'Content-Type':'application/json'},
        auth_headers: this.store.authStore.getAuthHeader()
      })
      .then(response => response.json())
      .then(data => {
        this.setResponse(data)
        this.resetFormData(this.getDefaultFormData())
      })
      .catch(err => err.json().then(data => Promise.reject(data?.detail)
      ))
      .catch(err => this.setViewErrorMessage(err))
    }
    
}
export default ArticleStore






