
import React, { Suspense }  from 'react';
import Router from "./router/Router";

import './assets/styles/App.css'
import { Provider } from "mobx-react";
import AppStore from './stores/appStore';
import CookieConsentBanner from './hooks/consent'
 
const store = new AppStore();

// <Suspense> lets you display a fallback until its children have finished loading.

const App = () => {
  return (

    <Suspense fallback={<Loading />}>
    <Provider store={store}>
    
         <Router />
         <CookieConsentBanner />
    </Provider>
  </Suspense>

  );
};

function Loading() {
  return <h2> Loading...</h2>;
}
export default App;
