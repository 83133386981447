

import React from 'react';
import { inject, observer } from 'mobx-react';
const set_cc = (s) => {
    const cc = {
    "br":"RSD/BRL",
    "ru":"RSD/RUS",
    "ch":"RSD/CHI",
    "in":"RSD/IND",
    "sa":"RSD/ZAR"
    }
    return cc[s]
}
const Courses = inject('store')(observer(({ store , page}) => {





    const cours =  store.coursesStore.courses.map((course, index) => (

        Object.entries(course).map(([key, value]) => (

          
         <div className="currency" key={key}> {set_cc(key)} {value} </div>
       ))
     ));


    return (
     
        <div className="currency-bar">

      <div className="words">{cours}</div>
     
      
      </div>
    );
  }));




export default Courses ;




