import { observable, action } from 'mobx';
import {AUTH_TOKEN, USER} from '../enums'

class AuthStore {
  store;
  token;
  isAuthenticated = observable(false);
  errors = "";
  values = observable({
    username: '',
    password: '',
  });
  user = observable(null);

  constructor(store) {
    this.store = store;
    this.token = localStorage.getItem(AUTH_TOKEN)
  
    if (this.token) {
      this.setAuthenticated(true);
      this.getUserIdentity()
    }
  };

  setAuthenticated = action(state => {
    this.isAuthenticated = state;
  })

  setUsername = action(username => {
    this.values.username = username;
  });

  setPassword = action(password => {
    this.values.password = password;
  });

  reset = action(() => {
    this.values.username = '';
    this.values.password = '';
  });

  setAuthToken = action(data => {
    this.token = data['access_token'];
    localStorage.setItem(AUTH_TOKEN, data['access_token']);
  });

  setUser = action(data => {
    if (data){
      this.user = {
        full_name: data['full_name'],
        email: data['email'],
        username: data['username'],
        role: data['role_name'] ? data['role_name'] : data['role']
      };    
      localStorage.setItem(USER, JSON.stringify(this.user))
    }
    else {
      localStorage.removeItem(USER)
    }
  })

  removeAuthToken(){
    localStorage.removeItem(AUTH_TOKEN);
    this.token = null;
    this.setAuthenticated(false)
  };

  getAuthHeader(){
    return this.token ? {'Authorization': `Bearer ${this.token}`} : ''
  };

  setErrorMessage = action((errorMessage) => {
      this.errors = errorMessage;
  });
   
  getUserIdentity() {
  

    const user = localStorage.getItem(USER)
    if (user){
        this.setUser(JSON.parse(user))
    }
    else {
        return this.store.apiStore.get({
            url: '/users/me',
            auth_headers: this.getAuthHeader()
        })
        .then(response => response.json())
        .then(data => {
            this.setAuthenticated(true);
            this.setUser(data)
            this.setErrorMessage("")
            window.location.replace('/')
        })
        .catch(err => {
          console.log('Could not retrieve user identity')
          this.removeAuthToken();
          this.setUser(null);
        })
      }
    };

  getUserRole = () => {
    return localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)).role.toLowerCase() : 'undefined'
  }

  login() {
    return this.store.apiStore.post({
      url: `/login/access-token`, 
      body: new URLSearchParams({
        'username': this.values.username,
        'password': this.values.password
    }), 
      headers: {'Content-Type': 'application/x-www-form-urlencoded', 'accept': 'application/json'}})
    .then(response => response.json())
    .then(data => {
      this.setAuthToken(data);
      this.getUserIdentity()
    })
    .catch(err => err.status === 401 || err.status === 400 ? err.json()
      .then(data => Promise.reject(data.detail || 'Unable to login')) : Promise.reject(err.statusText))
      .catch(err => this.setErrorMessage(err))
    };

}

export default AuthStore;
