import React, { Suspense, lazy} from 'react';
import { useParams } from 'react-router-dom';
import LeftSidebar from '../../components/Sidebar/LeftSidebar';
import RightSidebar from '../../components/Sidebar/RightSidebar';
import HeaderCompoent from '../../components/Header/HeaderComponent';
import Courses from '../../components/Courses/Courses';
const ArticleMain = lazy(() => import('../../components/Article/ArticleMain'));


const HomePage  = () => {
  const { page } = useParams() ;

 
  
  return (
    <>
      <Courses />
      <HeaderCompoent />
      {page}
      <div className="container">
        <LeftSidebar />
        <Suspense fallback={<div>Loading...</div>}>
          <ArticleMain page={page}/>
        
        </Suspense>
        <RightSidebar />
      </div>
      <footer></footer>
    </>
  );
};
export default HomePage;
