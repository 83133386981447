
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage/HomePage.js';
import ArticlePage from '../pages/ArticlePage/ArticlePage';
import Login from '../pages/authentication/login';
import ProtectedRoute from './ProtectedRoute.js';
const NotFoundPage = () => {
    return (
        <div>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default function Router(store) {

    return (
    <Routes>
        <Route exact path="/"  element={ <HomePage /> } />
        <Route exact path="/page/:page"  element={ <HomePage /> } />
        <Route path="/article"  element={<ProtectedRoute element={ArticlePage} />}  />
        <Route path="/article/:id"  element={<ProtectedRoute element={ArticlePage} />}  />
        <Route path="/login"  element={ <Login /> } /> 
        <Route path="*" element={<NotFoundPage />} />
    </Routes>    
    )
}

