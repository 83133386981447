// src/components/Header/HeaderComponent.js
import React,{useEffect}from 'react';

import logo from '../../assets/brics.png';
import { inject, observer } from 'mobx-react';

import {isAuthenticated} from '../../pages/authentication/isAuthenticated';


const HeaderComponent = inject('store')(observer(({ store }) => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        await store.coursesStore.getAllCourses();
      } catch (error) {
        console.error("Failed to fetch courses:", error);
      }
    };
    fetchData();
  }, [store]);


  const handleLogout = () => {
    store.authStore.removeAuthToken();
    store.authStore.setUser(null);
    window.location.replace("/login");
    
  }



  return (

      <div className="header-component">
        <img src={logo} className="header-component-logo" alt="logo" />
        
      <div className="brics fl-aw">

    
      </div>

      <nav className="nav-bar">
       {/* <Link to="/" className="btn">Home</Link>
        <Link to="/article" className="btn">Article</Link> */}
       {isAuthenticated && <button onClick = {handleLogout} >LogOut</button>} 
      </nav>
      </div>
  
  );
}));

export default HeaderComponent;

